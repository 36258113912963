<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.4vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height:'88%'}"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Imprime data de previsão:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-5"
          >
            <v-switch inset />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Altera preço unitário:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-5"
          >
            <v-switch inset />
          </v-col>
        </v-row>
        <v-form 
          ref="form"
          v-model="valid"
        >
          <v-row no-gutters>
            <v-col
              sm="10"
              md="10"
              :cols="$vssWidth < 600 ? 9 : 8"
              class="d-flex align-center"
            >
              Quantidade de dias para vencimento de orçamento:
            </v-col>
            <v-col
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 3 : 8"
              class="pt-5"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse             
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              sm="10"
              md="10"
              :cols="$vssWidth < 600 ? 9 : 8"
              class="d-flex align-center"
            >
              Quantidade de dias para vencimento de consignação:
            </v-col>
            <v-col
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 3 : 8"
              class="pt-5"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse       
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              sm="10"
              md="10"
              :cols="$vssWidth < 600 ? 9 : 8"
              class="d-flex align-center"
            >
              Quantidade de dias para vencimento de pedido:
            </v-col>
            <v-col
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 3 : 8"
              class="pt-5"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse   
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Agrupar itens iguais na entrada do pedido:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-5"
          >
            <v-switch inset />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      valid: false,

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>